import React, { useState, useEffect } from "react";
import SecondaryButton from "../SecondaryButton";
import Modal from "../Modal";

const FAQItem = ({ question, answer, isOpen, onClick }) => (
    <div className="faq-item mb-4">
        <SecondaryButton
            className="flex justify-between items-center cursor-pointer w-full"
            onClick={onClick}
        >
            <h3 className="text-base md:text-lg font-medium">{question}</h3>
            <span className="transform transition-transform duration-300">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                </svg>
            </span>
        </SecondaryButton>
    </div>
);

const FAQ = ({ page = "landing" }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [openIndex, setOpenIndex] = useState(null);
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        if (faqs.length === 0) {
            fetch("/api/faqs?page=" + page)
                .then((response) => response.json())
                .then(function (result) {
                    setFaqs(result.data);
                });
        }
    }, []);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
        setModalIsOpen(!modalIsOpen);
    };

    return (
        <div className="gap-4 text-left">
            {faqs.map((faq, index) => (
                <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    isOpen={modalIsOpen}
                    onClick={() => handleClick(index)}
                />
            ))}
            <Modal
                isOpen={modalIsOpen}
                setOpen={(open) => {
                    setModalIsOpen(open);
                }}
            >
                {openIndex !== null && (
                    <div className="p-4">
                        <h2 className="text-2xl font-bold mb-2">
                            {faqs[openIndex].question}
                        </h2>
                        <p className="text-lg">{faqs[openIndex].answer}</p>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default FAQ;
